import { makeStyles, createStyles } from '@material-ui/core';

export const useStylesProducView = makeStyles(theme =>
  createStyles({
    editor: {
      boxShadow: 'initial !important',
      padding: '0 !important',
    },
    container: {
      padding: '0',
      [theme.breakpoints.up('sm')]: {
        // padding: '0 50px',
      },
    },
    card: {
      padding: '20px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',

      [theme.breakpoints.up('sm')]: {
        padding: '50px',
        // maxWidth: '1280px',
        margin: 'auto',
      },
    },
    leftCol: {
      maxWidth: '690px',
      [theme.breakpoints.up('md')]: {
        width: '54%',
        marginRight: '20px',
      },
    },
    rightCol: {
      width: '100%',

      [theme.breakpoints.up('md')]: {
        width: '40%',
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    name: {
      fontSize: 20,
      fontWeight: 600,
      margin: 0,

      [theme.breakpoints.up('md')]: {
        fontSize: 34,
        lineHeight: 1.2,
      },
    },
    price: {
      color: theme.palette.primary.main,
      fontSize: 20,
      fontWeight: 700,

      [theme.breakpoints.up('md')]: {
        fontSize: 34,
        margin: '15px 0',
      },
    },
    whatsAppButton: {
      width: '100%',
      height: 42,
      padding: '12px 20px',
      borderRadius: 4,
      backgroundColor: '#25d366',
      fontSize: 14,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#fff',
      display: 'inline-block',
      marginTop: 20,

      '&:hover': {
        color: '#fff',
      },

      [theme.breakpoints.up('md')]: {
        width: 215,
      },
    },
    listItem: {
      marginBottom: 15,
      marginTop: 15
    },
    listValue: {
      fontSize: 17,
      fontWeight: 700,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#000000',
      whiteSpace: 'break-spaces',
      margin: 0,
    },
    listValueImportant: {
      fontSize: 17,
      fontWeight: 700,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: theme.palette.primary.main,
      whiteSpace: 'break-spaces',
      margin: 0,
    },
    listKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#757575',
    },
    companyCard: {
      padding: '20px 25px',
      borderRadius: '4px',
      backgroundColor: 'rgba(108,149,169,0.1)',
      marginTop: 50,
    },
    description: {
      fontSize: 17,
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: theme.palette.primary.main,
      whiteSpace: 'break-spaces',
      margin: 0,
    },
    descriptionView: {
      marginTop: '50px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    descriptionViewSmall: {
      marginTop: '30px',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    button: {
      backgroundColor: '#40271eFF',
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    containerProductPrices: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    containerPriceFrom: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end',
      marginRight: 35,
    },
    font17: {
      fontWeight: 'bold',
      fontSize: 17,
      color: '#000000',
      lineHeight: '17px',
    },
    productPrice: {
      fontWeight: 'bold',
      fontSize: 34,
      color: '#000000',
      margin: '0 5px',
      lineHeight: '28px',
    },
    estimatePrice: {
      fontWeight: 'bold',
      fontSize: 34,
      color: '#40271e',
      marginRight: 5,
      lineHeight: '28px',
    },
    font17Estimate: {
      fontWeight: 'bold',
      fontSize: 17,
      color: '#40271e',
      lineHeight: '17px',
    },
    estimateCell: {
      borderBottom: 'none',
      padding: 0,
      paddingTop: 15,
    },
    font17CompanyName: {
      fontWeight: 'bold',
      fontSize: 17,
      color: '#000000',
      lineHeight: '17px',
      marginBottom: 2,
    },
    font17Date: {
      fontWeight: 400,
      fontSize: 17,
      color: '#757575',
      lineHeight: '17px',
    },
    cellWidth200Btns: {
      minWidth: 200,
      maxWidth: 240,
      width: 200,
      borderBottom: 'none',
      padding: 0,
      paddingTop: 15,
      verticalAlign: 'top',
    },
    containerHeader: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    containerEstimate: {
      display: 'flex',
      flexDirection: 'column',
    },
    containerEstimatePrice: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end',
    },
    containerCompanyDate: {
      marginTop: 6,
    },
    containerBtns: {
      display: 'flex',
      flexDirection: 'row',
    },
    btnView: {
      display: 'flex',
      height: 42,
      width: 42,
      backgroundColor: '#40271e',
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      marginRight: 10,
    },
    viewICon: {
      width: 22,
      height: 22,
      color: '#ffffff',
    },
    bnsChoose: {
      display: 'flex',
      height: 42,
      width: 125,
      backgroundColor: '#e5761d',
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      marginRight: 10,
    },
    titleChoose: {
      fontWeight: 600,
      fontSize: 17,
      color: '#ffffff',
    },
    bottomCell: {
      paddingTop: 15,
      padding: 0
    },
    viewAllBtn: {
      paddingTop: 5,
      padding: 0,
      borderBottom: 'none',
    },
    font17ViewAll: {
      fontWeight: 'bold',
      fontSize: 17,
      color: '#40271e',
      lineHeight: '17px',
      textAlign: 'end',
      cursor: 'pointer'
    },
    bnsAddEstimate: {
      display: 'flex',
      height: 42,
      width: 300,
      backgroundColor: '#e5761d',
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      marginRight: 10,
      marginTop: 15,
      marginBottom: 20,
    },
  })
);
