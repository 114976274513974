/* eslint-disable no-plusplus, prefer-const */
import React, { useState } from 'react';
import { Table, TableBody, TableCell, Typography, TableRow, IconButton } from '@material-ui/core';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

import { thousands } from '../../../../utils/utils';
import { useStylesProducView } from '../hooks/useStylesProductView';
import { useFormatMessage } from '../../../../hooks';
import { IEstimate } from '../../../../interfaces/estimate';
import {IUser} from "../../../../interfaces/user";

type TProps = {
  sliceEstimates: IEstimate[];
  estimates: IEstimate[];
  me?: IUser;
  goViewEstimate: (id: number) => void;
  goEditEstimate: (id: number) => void;
  addEstimateCart: (estimate: IEstimate) => void;
  showAllEstimates: boolean;
  price: number;
  isAdmin?: boolean;
  isBuyer: boolean | null;
  isInhouseDesigner: boolean | null;
  isManger: boolean | null;
  isDesigner: boolean | null;
  setShowAll: (value: boolean) => void;
  deleteEstimate: (id: number) => void
};

const EstimatesTable: React.FC<TProps> = ({
  sliceEstimates,
  estimates,
  goViewEstimate,
  addEstimateCart,
  showAllEstimates,
  setShowAll,
  goEditEstimate,
  me,
  isBuyer,
  isAdmin,
  isManger,
  isDesigner,
  isInhouseDesigner,
  price,
  deleteEstimate
}) => {
  const classes = useStylesProducView();
  const fm = useFormatMessage();

  return (
    <Table aria-label='simple table'>
      <TableBody>
        <TableRow>
          <TableCell colSpan={2} className={classes.estimateCell}>
            <div className={classes.containerProductPrices}>
              <div className={classes.containerPriceFrom}>
                <Typography className={classes.font17}>{fm('ESTIMATE.PRICE.FROM')}</Typography>
                <Typography className={classes.productPrice}>
                  {thousands((price || 0).toString())}
                </Typography>
                <Typography className={classes.font17}>{fm('RUBL')}</Typography>
              </div>
            </div>
          </TableCell>
        </TableRow>
        {sliceEstimates.map(i => (
          <TableRow key={i.id}>
            <TableCell className={classes.estimateCell}>
              <div className={classes.containerEstimate}>
                <div className={classes.containerEstimatePrice}>
                  <Typography className={classes.estimatePrice}>
                    {thousands((i.price || 0).toString())}
                  </Typography>
                  <Typography className={classes.font17Estimate}>{fm('RUBL')}</Typography>
                </div>
                <div className={classes.containerCompanyDate}>
                  {i.company && (
                    <Typography className={classes.font17CompanyName}>
                      {i.company.name}
                    </Typography>
                  )}
                  {i.date && (
                    <Typography className={classes.font17Date}>
                      {moment(i.date).format('DD.MM.YYYY')}
                    </Typography>
                  )}
                </div>
              </div>
            </TableCell>
            <TableCell className={classes.cellWidth200Btns}>
              <div className={classes.containerBtns}>
                <div className={classes.btnView} onClick={() => goViewEstimate(i.id)}>
                  <VisibilityIcon className={classes.viewICon} />
                </div>
                {!me || (isBuyer || isDesigner) ? (<div className={classes.bnsChoose} onClick={() => {
                       addEstimateCart(i)
                    }}>
                      <Typography className={classes.titleChoose}>
                        {fm('TARIFFS.CHOOSE').toLocaleUpperCase()}
                      </Typography>
                    </div>
                ):(
                    <>
                      {(isAdmin || isManger) ||me?.company && me.company.id === i.company?.id ? (
                          <div className={classes.bnsChoose} onClick={() => {
                            goEditEstimate( i.id);
                          }}>
                            <Typography className={classes.titleChoose}>
                              {fm('COMPANY.TABLE.BUTTON.EDIT').toLocaleUpperCase()}
                            </Typography>
                          </div>
                      ):null}
                    </>
                )}

                {(isAdmin || isManger) || me?.company && me.company.id === i.company?.id ? (
                  <IconButton
                  color='secondary'
                  aria-label='previous'
                  onClick={()=> deleteEstimate(i.id)}>
                    <DeleteIcon />
                  </IconButton>
                ):null}
              </div>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell className={classes.bottomCell} colSpan={2} />
        </TableRow>
        {estimates.length > 5 &&  (
          <TableRow>
            <TableCell className={classes.viewAllBtn} colSpan={2}>
              {!showAllEstimates ? (
              <Typography className={classes.font17ViewAll} onClick={() => setShowAll(!showAllEstimates)}>
                {fm('VIEW.ALL.ESTIMATE')}
              </Typography>
              ):(
              <Typography className={classes.font17ViewAll} onClick={() => setShowAll(!showAllEstimates)}>
                {fm('COLLAPSE.ALL.ESTIMATE')}
              </Typography>
              ) }
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default React.memo(EstimatesTable);
