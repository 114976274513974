import axios from 'axios';
import {
  createReviewUrl,
  delPhotoFromProduct,
  delReviewUrl,
  FOR_DAYS_URL,
  getProductByIdUrl,
  getReviewsUrl,
  PRODUCT_URL,
  PRODUCTS_URL,
  setProductMainPhotoUrl,
} from '../constants';
import { IProductFilter, IReviewCreate } from '../interfaces/product';
import { ILocation } from '../interfaces/locations';

export const createProduct = (files: FormData) =>
  axios.post(PRODUCT_URL, files, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const editProduct = (id: number, files: FormData) =>
  axios.post(getProductByIdUrl(id), files, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const deleteProduct = (id: number | undefined) =>
  axios.delete(getProductByIdUrl(id || -1));

export const getProductById = (id: number) => axios.get(getProductByIdUrl(id));

export const getProducts = ({
  page = 1,
  perPage = 20,
  companyId,
  categoryId,
  search,
  filter = {
    price_from: '',
    price_to: '',
  },
  locale,
  special_type,
}: {
  page: number;
  perPage: number;
  companyId?: number;
  categoryId?: number;
  search?: string;
  filter?: Partial<IProductFilter>;
  locale?: ILocation | null;
  special_type?: string;
}) => {
  if (search) {
    return axios.get('/api/shop/products/all', {
      params: {
        page,
        per_page: perPage,
        text: search,
        special_type,
      },
    });
  }
  if (companyId) {
    return axios.get(`/api/shop/company/${companyId}/products`, {
      params: {
        page,
        per_page: perPage,
        category_id: categoryId,
        special_type,
      },
    });
  }
  if ((categoryId as number) >= 0) {
    if (!filter.parameters) {
      return axios.post(
        `/api/shop/category/${categoryId}/products`,
        {},
        {
          params: {
            page,
            per_page: perPage,
            lat: locale?.lat,
            lng: locale?.lng,
            sort_type: 'distantion',
            special_type,
          },
        }
      );
    }
    const formData = new FormData();
    formData.append('parameters', JSON.stringify(filter.parameters));
    return axios.post(`/api/shop/category/${categoryId}/products`, formData, {
      params: {
        page,
        per_page: perPage,
        // price_from: filter.price_from,
        // price_to: filter.price_to,
        lat: locale?.lat,
        lng: locale?.lng,
        sort_type: 'distantion',
        special_type,
      },
    });
  }

  if (filter.parameters) {
    const formData = new FormData();
    formData.append('parameters', JSON.stringify(filter.parameters));
    return axios.post(`/api/shop/category/${categoryId || 0}/products`, formData, {
      params: {
        page,
        per_page: perPage,
        price_from: filter.price_from,
        price_to: filter.price_to,
        special_type,
      },
    });
  }

  return axios.post(
    PRODUCTS_URL,
    {},
    {
      params: {
        page,
        per_page: perPage,
        special_type,
      },
    }
  );
};

export const delPhoto = (photoId: number) => axios.delete(delPhotoFromProduct(photoId));

export function setMainPhoto(id: number) {
  return axios.post(setProductMainPhotoUrl(id));
}

export const getReviews = (productId: number, perPage: number, page: number) =>
  axios.get(getReviewsUrl(productId, perPage, page));

export const createReview = (productId: number, body: IReviewCreate) =>
  axios.post(createReviewUrl(productId), body);

export const delReview = (reviewId: number) => axios.delete(delReviewUrl(reviewId));

export const getMyFavorites = () => axios.get('/api/shop/favorites');

export const addMyFavorites = (product_id: number) =>
  axios.post('/api/shop/favorites', {}, { params: { product_id } });

export const deleteMyFavorites = (product_id: number) =>
  axios.delete('/api/shop/favorites', { params: { product_id } });

export const changeMyFavorites = (product_id: number, deleteFav: boolean) =>
  deleteFav ? deleteMyFavorites(product_id) : addMyFavorites(product_id);

export const getProductsForDays = ({
  page = 1,
  perPage = 20,
  forDays = 0,
}: {
  page: number;
  perPage: number;
  forDays: number;
}) =>
  axios.post(
    PRODUCTS_URL,
    {
      for_days: forDays,
    },
    {
      params: {
        page,
        per_page: perPage,
      },
    }
  );

export const getForDays = () => axios.get(FOR_DAYS_URL);
export const addForDays = (days: number) => axios.post(`${FOR_DAYS_URL}?days=${days}`);

export const editStock = (product_id: number, body: FormData) =>
  axios.post(`/api/stock_info/${product_id}`, body);
