/* eslint-disable no-plusplus, prefer-const */
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Formik } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Card, Button, ButtonGroup, Typography, IconButton } from '@material-ui/core';

import { ImageGallery } from './components';

import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { actions as productActions } from '../../../store/ducks/product.duck';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { actions as cartTypesActions } from '../../../store/ducks/cart.duck';
import { UserRoles } from '../../../interfaces/user';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { IProduct } from '../../../interfaces/product';
import { formatAsThousands } from '../../../utils/utils';
import { useStylesProducView } from './hooks/useStylesProductView';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import { useChangeFavorite } from './hooks/useChangeMyFavorite';
import { useCreateChat } from '../chats/chatsPage/hooks/useCreateChat';
import { handleProductFiles } from './hooks/handleUploadFiles';
import { IFile } from '../../../interfaces/file';
import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { ILocation } from '../../../interfaces/locations';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import { useGetEstimates } from '../estimate/hooks/useGetEstimates';
import EstimatesTable from './components/EstimatesTable';
import { IEstimate } from '../../../interfaces/estimate';


const ProductView: React.FC<RouteComponentProps<{ id: string }> & TPropsFromRedux> = ({
  match: {
    params: { id },
  },
  product,
  loading,
  isAuthorized,
  guestCart,
  cart,
  isAdmin,
  me,
  loadingMe,
  editLoading,
  cartCompanyId,
  companyGuestCart,
  countProductsGuest,
  addProductLoading,
  fetch,
  clearProduct,
  setCountGuestCart,
  setProductCount,
  setProductCountLoading,
  fetchMe,
  clearMe,
  addProductToCart,
  setProductGuestCart,
  loadingCompanies,
  fetchCompanies,
  pageCompany,
  perPageCompany,
  companies,
}) => {
  const intl = useIntl();
  const classes = useStylesProducView();
  const fm = useFormatMessage();
  const history = useHistory();
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const isManger = useDefineUserRole(me, 'ROLE_MANAGER');
  const isInhouseDesigner = useDefineUserRole(me, 'ROLE_INHOUSE_DESIGNER');
  const isDesigner = useDefineUserRole(me, 'ROLE_DESIGNER');
  const isVendor = useMemo(() => me && me.roles.includes(UserRoles.ROLE_VENDOR), [me]);
  const isRepairer = useMemo(() => me && me.roles.includes(UserRoles.ROLE_REPAIRER), [me]);
  const isAgent = useMemo(() => me && me.roles.includes(UserRoles.ROLE_AGENT), [me]);
  const [isCartAlertOpen, setCartAlertOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<IFile[] | undefined>([]);
  const [showAllEstimates, setShowAll] = useState(false);
  const [changeFavorite, loadingChange, successChange] = useChangeFavorite();
  const [createChatFetch, loadingCreated, createChat] = useCreateChat();
  const [alertStockOver, setAlertStockOver] = useState(false);
  const [estimateId, setEstimateId] = useState<number | null>(null);
  const [estimateAlert, setEstimateAlertOpen] = useState(false);
  const [estimateSwisthAlert, setEstimateSwisthAlertOpen] = useState(false);
  const [productEstimateAlert, setProductEstimateAlertOpen] = useState(false);
  const [productAlert, setProductAlertOpen] = useState(false);

  const { fetchEstimates, estimates, deleteEstimate } = useGetEstimates();
  const sortedEstimates = useMemo(() => {
    if (isRepairer){
      return [...estimates].sort((a, b) => {
        if (a.company?.id === me?.company?.id) {
          return -1;
        }
        return 0;
      });
    }
      return estimates
  }, [estimates, me]);

  const sliceEstimates = useMemo(() => {
    return showAllEstimates ? sortedEstimates : sortedEstimates.slice(0, 5)
  },[showAllEstimates, sortedEstimates])

  const companyProduct = useMemo(
    () => companies.find(company => company.id === product?.company?.id),
    [companies, product]
  );

  const isADP = useMemo(() => product?.special_type === 'adp', [product]);

  const location = useMemo<ILocation | undefined | null>(() => {
    const localLocation = localStorage.getItem('location');
    const parseLocalLocation = localLocation && JSON.parse(localLocation);
    return parseLocalLocation as ILocation;
  }, []);

  setLayoutSubheader({
    title: isADP ? fm('PROJECT.ADP') : product?.name || '',
    breadcrumb: [
      {
        title: fm('MENU.PRODUCTS.CATALOG'),
        root: true,
        page: 'products/catalog',
        translate: 'MENU.PRODUCTS.CATALOG',
      },
    ],
    back: true,
  });
  setLayoutFooter({ show: true });

  useEffect(() => {
    fetch(Number(id));
    fetchEstimates(Number(id), 1, 100500, (!!isBuyer || !!isDesigner));
    return () => {
      clearProduct();
    };
  }, [id]);

  useLayoutEffect(() => {
    successChange && fetch(Number(id));
  }, [successChange]);

  // handle add to cart
  const [cartProductId, setCartProductId] = useState<IProduct | null>(null);

  useEffect(() => {
    fetchMe();
    fetchCompanies({
      page: pageCompany,
      perPage: perPageCompany,
      lng: location?.lng,
      lat: location?.lat,
    });
    return () => {
      clearMe();
    };
  }, []);

  const characteristics = useMemo(() => {
    if (product) {
      const result: { [key: string]: any } = !isADP ? {
        [intl.formatMessage({ id: 'PRODUCT.VIEW.CATEGORY' })]:
          product.category?.name || intl.formatMessage({ id: 'COMMON.NO_DATA' }),
      } : {};

      product.parameter_values?.forEach(item => {
        if (item.parameter_id && item.parameter_name) {
          // запрос не возвращает название параметра
          result[item.parameter_name] = item.value;
        }
      });
      return result;
    }
    return {};
  }, [product]);

  const isCompanyInfo = useMemo(() => {
    return (
      product?.company?.brand ||
      // product.company?.address ||
      product?.company?.phone_number ||
      product?.company?.site
      // || product.company?.working_hours
    );
  }, [product?.company]);

  const companyInfo: { [key: string]: any } | null = isCompanyInfo
    ? {
        [intl.formatMessage({ id: 'PRODUCT.VIEW.COMPANY' })]: product?.company?.brand || null,
        // Адрес: product.company?.address || null,
        [intl.formatMessage({ id: 'PRODUCT.VIEW.PHONE_NUMBER' })]:
          (companyProduct?.nearest_store?.phone_number
            ? companyProduct?.nearest_store?.phone_number
            : companyProduct?.phone_number) || null,
        // maskPhone(product?.company?.phone_number) || null,
        [intl.formatMessage({ id: 'PRODUCT.VIEW.SITE' })]: product?.company?.site || null,
        [intl.formatMessage({ id: 'ADDRESS' })]:
          companyProduct?.nearest_store?.location?.address || null,
        [intl.formatMessage({ id: 'PRODUCT.VIEW.DISTANCE' })]:
          companyProduct?.nearest_store &&
          typeof companyProduct?.nearest_store?.distantion === 'number' &&
          companyProduct?.nearest_store?.distantion >= 0
            ? `${(companyProduct?.nearest_store?.distantion).toFixed(2)} км`
            : null,
        // 'Часы работы': product.company?.working_hours
        //   ? product.company?.working_hours.replace(/(\r\n|\n|\r)/gm, '<br>')
        //   : null,
      }
    : null;

  let productCount = useMemo(() => {
    if (!isAuthorized && product && guestCart) {
      const item = guestCart.items.find(item => item.product.id === product.id);
      if (item) {
        return item.count;
      }
    } else if (Boolean(isAuthorized) && product && cart) {
      const item = cart.items.find(item => item.product.id === product.id);
      if (item) {
        return item.count;
      }
    }
    return null;
  }, [isAuthorized, product, guestCart?.goods_num, cart?.goods_num, guestCart, cart]);

  const addEstimateAction = useCallback(() => {
    if (estimateId) {
      addProductToCart({
        product_id: 0,
        estimate_id: estimateId,
        count: 1,
        newCart: true,
      });
      setEstimateId(null);
      setEstimateAlertOpen(false);
      setEstimateSwisthAlertOpen(false);
    }
  }, [estimateId, addProductToCart]);

  const addEstimateCart = useCallback(
    (estimate: IEstimate) => {
      if (isAuthorized) {
        if (cart && cart.items?.length === 0 && !cart.estimate) {
          addProductToCart({
            product_id: 0,
            estimate_id: estimate.id,
            count: 1,
            newCart: true,
          });
        } else if (cart && cart.items?.length > 0 && !cart.estimate) {
          setEstimateId(estimate.id);
          setEstimateAlertOpen(true);
        } else if (cart && cart.estimate) {
          setEstimateId(estimate.id);
          setEstimateSwisthAlertOpen(true);
        } else {
          addProductToCart({
            product_id: 0,
            estimate_id: estimate.id,
            count: 1,
            newCart: true,
          });
        }
      }
    },
    [cart, isAuthorized]
  );

  const handleCartDialog = useCallback(
    (item: IProduct) => {
      // const stock = isBuyer || !me ? item.stock_info?.available_quantity : item.stock_quantity;
      if (isAuthorized) {
        if (cart && cart.estimate) {
          setCartProductId(item);
          setProductEstimateAlertOpen(true);
        } else {
          let cartSpecialType;
          cart?.items.forEach(i => {
            cartSpecialType = i.product.special_type;
          });

          if (cartSpecialType === 'service' || cartSpecialType === null) {
            if (item.special_type !== cartSpecialType) {
              setCartProductId(item);
              setProductAlertOpen(true);
            } else {
              const newCart = cartCompanyId ? item?.company?.id !== cartCompanyId : false;
              if (!newCart) {
                addProductToCart({
                  product_id: item.id ? item?.id : 0,
                  count: 1,
                });
              } else {
                setCartProductId(item);
                setCartAlertOpen(true);
              }
            }
          } else {
            const newCart = cartCompanyId ? item?.company?.id !== cartCompanyId : false;
            if (!newCart) {
              addProductToCart({
                product_id: item.id ? item?.id : 0,
                count: 1,
              });
            } else {
              setCartProductId(item);
              setCartAlertOpen(true);
            }
          }
        }
      } else {
        // const countProduct = guestCart?.items.find(i => i.product.id === item.id)?.count || 0;
        const newCart = companyGuestCart ? item?.company?.id !== companyGuestCart : false;
        // if (!stock || countProduct === stock || countProduct > stock) {
        //   setAlertStockOver(true);
        // } else
        if (!newCart) {
          setProductGuestCart({ data: item, type: 'cart' });
        } else if (countProductsGuest === 0) {
          setProductGuestCart({ data: item, type: 'new' });
        } else {
          setCartProductId(item);
          setCartAlertOpen(true);
        }
      }
    },
    [
      addProductToCart,
      cartCompanyId,
      companyGuestCart,
      setProductGuestCart,
      guestCart,
      isBuyer,
      me,
      cart,
    ]
  );

  const goEstimate = useCallback(() => {
    product && history.push({ pathname: '/estimate/add', state: { projectId: product.id } });
  }, [product]);
  const goEditEstimate = useCallback((id: number) => {
    product && history.push({ pathname: `/estimate/edit/${id}`, state: { projectId: product.id } });
  }, [product]);

  const goViewEstimate = useCallback(
    (id: number) => {
      product && history.push(`/estimate/view/${id}`);
    },
    [product]
  );

  const addCartProductAction = useCallback(() => {
    if (!addProductLoading && cartProductId) {
      addProductToCart({
        product_id: cartProductId.id!,
        count: 1,
        newCart: true,
      });
      setCartAlertOpen(false);
      setProductEstimateAlertOpen(false);
      setProductAlertOpen(false);
    }
  }, [cartProductId, addProductToCart]);

  const addProductGuestCart = useCallback(() => {
    if (cartProductId) {
      setProductGuestCart({ data: cartProductId, type: 'new' });
      setCartAlertOpen(false);
    }
  }, [cartProductId, setProductGuestCart]);

  const newChat = useCallback((userId?: string | number) => {
    createChatFetch({ userId });
  }, []);

  useEffect(() => {
    if (product) {
      setFiles(
        product?.attachments?.map(({ id, path, title, size }) => ({
          id,
          path,
          title,
          size,
        }))
      );
    }
  }, [product]);

  const [uploadFile, deleteFile] = handleProductFiles(product);
  const myEstimates = me && estimates && estimates.some(e => e.company && e.company.id === me.company?.id);
  if (loading || loadingMe || !product || loadingCreated || loadingCompanies)
    return <Preloader />;

  return (
    <>
      <AlertDialog
        open={alertStockOver}
        message={intl.formatMessage({ id: 'CART.PRODUCTS.STOCK.OVER' })}
        okText=''
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        handleClose={() => {
          setAlertStockOver(false);
        }}
        handleAgree={() => {
          setAlertStockOver(false);
        }}
      />
      <AlertDialog
        open={isCartAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setCartAlertOpen(false);
        }}
        handleAgree={() => (isAuthorized ? addCartProductAction() : addProductGuestCart())}
      />
      <AlertDialog
        open={estimateAlert}
        message={intl.formatMessage({ id: 'ESTIMATE.ALERT.TITLE' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setEstimateAlertOpen(false);
        }}
        handleAgree={() => isAuthorized && addEstimateAction()}
      />
      <AlertDialog
        open={estimateSwisthAlert}
        message={intl.formatMessage({ id: 'ESTIMATE.SWITCH.ALERT.TITLE' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setEstimateSwisthAlertOpen(false);
        }}
        handleAgree={() => isAuthorized && addEstimateAction()}
      />
      <AlertDialog
        open={productEstimateAlert}
        message={intl.formatMessage({ id: 'PRODUCT.ESTIMATE.ALERT.TITLE' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setProductEstimateAlertOpen(false);
        }}
        handleAgree={() => isAuthorized && addCartProductAction()}
      />
      <AlertDialog
        open={productAlert}
        message={intl.formatMessage({ id: 'PRODUCT.ALERT.TITLE' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setProductAlertOpen(false);
        }}
        handleAgree={() => isAuthorized && addCartProductAction()}
      />
      <div className={classes.container}>
        <Card className={classes.card}>
          <div className={classes.leftCol}>
            <ImageGallery product={product} />

            <div className={classes.descriptionView}>
              {product.description && (
                <p
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
              )}
            </div>
          </div>

          <div className={classes.rightCol}>
            <div className={classes.header}>
              <div className={classes.containerHeader}>
                <p className={classes.name}>
                  <b>{product.name}</b>
                </p>

                {isADP && estimates.length > 0 && (
                  <EstimatesTable
                    sliceEstimates={sliceEstimates}
                    estimates={estimates}
                    goViewEstimate={goViewEstimate}
                    addEstimateCart={addEstimateCart}
                    showAllEstimates={showAllEstimates}
                    goEditEstimate={goEditEstimate}
                    price={product.price}
                    me={me}
                    isBuyer={isBuyer}
                    isManger={isManger}
                    isInhouseDesigner={isInhouseDesigner}
                    isDesigner={isDesigner}
                    isAdmin={isAdmin}
                    setShowAll={setShowAll}
                    deleteEstimate={deleteEstimate}
                  />
                )}

                {!isADP && product.price && (
                  <p className={classes.price}>{formatAsThousands(product.price)} руб.</p>
                )}
              </div>
              {((isAdmin || isManger ) || (isRepairer && !myEstimates)) && isADP && (
                <div className={classes.bnsAddEstimate} onClick={goEstimate}>
                  <Typography className={classes.titleChoose}>
                    {fm('ESTIMATE.BUTTON.MY.ADD').toLocaleUpperCase()}
                  </Typography>
                </div>
              )}
              {!isADP && (
                <div>
                  <Formik initialValues={{}} onSubmit={() => handleCartDialog(product)}>
                    {({ handleSubmit }) => (
                      <form onSubmit={handleSubmit} style={{ paddingBottom: 10 }}>
                        {!productCount
                          ? !isAdmin &&
                            !isManger &&
                            !isVendor &&
                            !isRepairer &&
                            !isInhouseDesigner &&
                            !isAgent && (
                              <ButtonWithLoader disabled={editLoading} loading={editLoading}>
                                {intl.formatMessage({ id: 'PRODUCT.BUTTON.ADD_CART' })}
                              </ButtonWithLoader>
                            )
                          : product &&
                            Boolean(productCount) && (
                              <ButtonGroup
                                color='primary'
                                aria-label='outlined primary button group'
                                variant='contained'
                              >
                                <Button
                                  // disabled={setProductCountLoading}
                                  onClick={() =>
                                    isAuthorized
                                      ? setProductCount({
                                          product_id: product.id || 0,
                                          count: --productCount!,
                                        })
                                      : setCountGuestCart({
                                          product_id: product.id || 0,
                                          count: --productCount!,
                                          type: 'dec',
                                        })
                                  }
                                  className={classes.button}
                                  style={{ borderColor: 'white' }}
                                >
                                  <RemoveIcon fontSize='small' />
                                </Button>
                                <Button
                                  style={{
                                    pointerEvents: 'none',
                                    backgroundColor: '#40271eFF',
                                    color: 'white',
                                    borderColor: 'white',
                                  }}
                                >
                                  {productCount}
                                </Button>
                                <Button
                                  // disabled={setProductCountLoading}
                                  onClick={() =>
                                    isAuthorized
                                      ? setProductCount({
                                          product_id: product.id || 0,
                                          count: ++productCount!,
                                        })
                                      : setCountGuestCart({
                                          product_id: product.id || 0,
                                          count: ++productCount!,
                                          type: 'inc',
                                        })
                                  }
                                  className={classes.button}
                                >
                                  <AddIcon fontSize='small' />
                                </Button>
                              </ButtonGroup>
                            )}
                      </form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
            {
              Object.keys(characteristics).map(key => (
                <>
                  {characteristics[key] && characteristics[key] !== 'null' && (
                    <div key={key} className={classes.listItem}>
                      <p className={classes.listValue}>
                        {key === 'Размер'
                          ? characteristics[key]
                              .split(',')
                              .map((el: string, index: number) =>
                                index !== characteristics[key].split(',').length - 1
                                  ? el + ', '
                                  : el
                              )
                          : characteristics[key]}
                        {/* {characteristics[key]} */}
                      </p>
                      <p className={classes.listKey}>{key}</p>
                    </div>
                  )}
                </>
              ))}
            <div className={classes.descriptionViewSmall}>
              {product.description && (
                <p
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
              )}
            </div>
            {companyInfo && (
              <div className={classes.companyCard}>
                {Object.keys(companyInfo).map(key => {
                  return (
                    <>
                      {companyInfo[key] && (
                        <div key={key} className={classes.listItem}>
                          {key === 'Номер телефона' ? (
                            <a
                              href={`tel:${companyInfo[key]}`}
                              className={
                                key === 'Номер телефона'
                                  ? classes.listValueImportant
                                  : classes.listValue
                              }
                              dangerouslySetInnerHTML={{ __html: companyInfo[key] }}
                            />
                          ) : (
                            <p
                              className={
                                key === 'Сайт' ? classes.listValueImportant : classes.listValue
                              }
                              dangerouslySetInnerHTML={{ __html: companyInfo[key] }}
                            />
                          )}
                          <p className={classes.listKey}>{key}</p>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            )}
            {/* {product.company && product.company.phone_number && ( */}
            {/*  <a */}
            {/*    rel='noreferrer' */}
            {/*    target='_blank' */}
            {/*    href={`https://wa.me/${cleanPhone(product.company.phone_number)}`} */}
            {/*    className={classes.whatsAppButton} */}
            {/*  > */}
            {/*    {intl.formatMessage({ id: 'PRODUCT.VIEW.WHATSAPP' })} */}
            {/*  </a> */}
            {/* )} */}
          </div>
          {/* <div style={{width: '100%'}}> */}
          {/*  <ReviewsForm product={product} meAdd={meAdd} name={me?.fio || ''}/> */}
          {/* </div> */}
        </Card>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    product: state.products.product,
    loadingCompanies: state.companies.loading,
    companies: state.companies.companies,
    loading: state.products.byIdLoading,
    meAdd: state.products.reviewsProduct?.meAdd || false,
    isAuthorized: state.auth.user != null,
    guestCart: state.cart.guestCart,
    cart: state.cart.cart,
    setProductCountLoading: state.cart.setProductCountLoading,
    isAdmin: state.auth.user?.is_admin,
    me: state.profile.me,
    loadingMe: state.profile.loading,
    editLoading: state.products.editLoading,
    cartCompanyId: state.cart.companyId,
    companyGuestCart: state.cart.guestCart?.cartCompanyId,
    countProductsGuest: state.cart.guestCart?.items.length,
    addProductLoading: state.cart.addProductLoading,
    pageCompany: state.companies.page,
    perPageCompany: state.companies.per_page,
  }),
  {
    fetchCompanies: companiesActions.fetchRequest,
    fetch: productActions.fetchByIdRequest,
    clearProduct: productActions.clearProduct,
    setProductGuestCart: cartTypesActions.setProductGuestCart,
    setCountGuestCart: cartTypesActions.setCountGuestCart,
    setProductCount: cartTypesActions.setProductCountRequest,
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    addProductToCart: cartTypesActions.addProductRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductView);
