import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';
import { getEstimates , deliteEstimate } from '../../../../crud/estimate';
import { IEstimate } from '../../../../interfaces/estimate';


export const useGetEstimates = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingEstimates, setLoading] = useState(false);
  const [loadingDelEstimates, setDelLoading] = useState(false);
  const [pageEstimates, setPage] = useState(1);
  const [perPageEstimates, setPerPage] = useState(20);
  const [totalEstimates, setTotal] = useState(0);
  const [estimates, setEstimates] = useState<IEstimate[]>([]);
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const fetchEstimates = useCallback(
    async (project_id: number | string, page = 1, per_page = 20, active?: boolean) => {
      setLoading(true);
      getEstimates(project_id, page, per_page, active)
        .then(res => {
          setTotal(res.data.total);
          setPage(res.data.page);
          setPerPage(res.data.per_page);
          setEstimates(res.data.data);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch]
  );

  const deleteEstimate = useCallback(
    async (id: number) => {
      setDelLoading(true)
      deliteEstimate(id)
        .then(() => {
          const deliteEsmi = estimates.filter(item => item.id !== id)
          setEstimates(deliteEsmi)
        })
        .catch(e => {
          enqueueSnackbar(`Ошибка: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
        setDelLoading(false)
        });
    },
    [estimates]
  );

  return {
    fetchEstimates,
    estimates,
    loadingEstimates,
    pageEstimates,
    perPageEstimates,
    totalEstimates,
    deleteEstimate,
    loadingDelEstimates
  };
};
